<template>
  <div style="min-height: 750px;" v-if="d_userShopBundleWdm">
    <b-row>
      <b-col sm="12" lg="2"></b-col>
      <b-col sm="12" lg="8"></b-col>
      <b-col sm="12" lg="2"></b-col>
    </b-row>
    <b-row style="margin-bottom: 20px; border-bottom: solid 1px whitesmoke;">
      <b-col sm="12" md="2" lg="2"> </b-col>
      <b-col sm="12" md="4" lg="4">
        <i class="fa fa-desktop"></i>
        {{ StoreLangTranslation.data['shop_bundle_record'][StoreLang] }}
      </b-col>
      <b-col sm="12" md="4" lg="4" :style="StoreDevice.isMobile ? '' : 'text-align: right;'"></b-col>
      <b-col sm="12" md="2" lg="2"></b-col>
    </b-row>
    <b-row>
      <b-col sm="12" lg="2"></b-col>
      <b-col sm="12" lg="8">
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleWdm['0']['parameters']['0']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
            <select class="form-control" v-model="d_userShopBundleData.status">
              <option v-for="(op, op_index) in d_userShopBundleWdm['0']['parameters']['0'].options" :value="op.value">
                {{ op.translation[StoreLang] }}
              </option>
            </select>
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleWdm['0']['parameters']['1']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
          </b-col>
        </b-row>
        <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
          <b-col sm="12" lg="1">
            {{ lang.label }}
          </b-col>
          <b-col sm="12" lg="11">
            <b-form-textarea class="form-control" rows="5" v-model="d_userShopBundleData.bundle_name[lang.label]"></b-form-textarea>
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleWdm['0']['parameters']['4']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
          </b-col>
        </b-row>
        <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
          <b-col sm="12" lg="1">
            {{ lang.label }}
          </b-col>
          <b-col sm="12" lg="11">
            <b-form-input type="text" v-model="d_userShopBundleData.organization_id[lang.label]"></b-form-input>
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleWdm['0']['parameters']['5']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
          </b-col>
        </b-row>
        <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
          <b-col sm="12" lg="1">
            {{ lang.label }}
          </b-col>
          <b-col sm="12" lg="11">
            <b-form-input type="text" v-model="d_userShopBundleData.organization_name[lang.label]"></b-form-input>
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleWdm['0']['parameters']['6']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
          </b-col>
        </b-row>
        <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
          <b-col sm="12" lg="1">
            {{ lang.label }}
          </b-col>
          <b-col sm="12" lg="11">
            <b-form-input type="text" v-model="d_userShopBundleData.organization_user_id[lang.label]"></b-form-input>
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleWdm['0']['parameters']['7']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
          </b-col>
        </b-row>
        <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
          <b-col sm="12" lg="1">
            {{ lang.label }}
          </b-col>
          <b-col sm="12" lg="11">
            <b-form-input type="text" v-model="d_userShopBundleData.organization_username[lang.label]"></b-form-input>
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleWdm['0']['parameters']['8']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
            <select class="form-control" v-model="d_userShopBundleData.priority">
              <option v-for="(op, op_index) in d_userShopBundleWdm['0']['parameters']['8'].options" :value="op.value">
                {{ op.translation[StoreLang] }}
              </option>
            </select>
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleWdm['0']['parameters']['9']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
            <select class="form-control" v-model="d_userShopBundleData.task_type">
              <option v-for="(op, op_index) in d_userShopBundleWdm['0']['parameters']['9'].options" :value="op.value">
                {{ op.translation[StoreLang] }}
              </option>
            </select>
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleWdm['0']['parameters']['10']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
          </b-col>
        </b-row>
        <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
          <b-col sm="12" lg="1">
            {{ lang.label }}
          </b-col>
          <b-col sm="12" lg="11">
            <b-form-input type="text" v-model="d_userShopBundleData.bundle_group[lang.label]"></b-form-input>
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleWdm['0']['parameters']['12']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
            <select class="form-control" v-model="d_userShopBundleData.available_soon">
              <option v-for="(op, op_index) in d_userShopBundleWdm['0']['parameters']['12'].options" :value="op.value">
                {{ op.translation[StoreLang] }}
              </option>
            </select>
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleWdm['0']['parameters']['13']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
            <select class="form-control" v-model="d_userShopBundleData.prices_on_the_screen">
              <option v-for="(op, op_index) in d_userShopBundleWdm['0']['parameters']['13'].options" :value="op.value">
                {{ op.translation[StoreLang] }}
              </option>
            </select>
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleWdm['0']['parameters']['20']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
            <select class="form-control" v-model="d_userShopBundleData.in_store_visibility">
              <option v-for="(op, op_index) in d_userShopBundleWdm['0']['parameters']['20'].options" :value="op.value">
                {{ op.translation[StoreLang] }}
              </option>
            </select>
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleWdm['0']['parameters']['21']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
            <select class="form-control" v-model="d_userShopBundleData.can_customer_add_to_shopcart">
              <option v-for="(op, op_index) in d_userShopBundleWdm['0']['parameters']['21'].options" :value="op.value">
                {{ op.translation[StoreLang] }}
              </option>
            </select>
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleWdm['0']['parameters']['14']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
            <select class="form-control" v-model="d_userShopBundleData.expiration_time_type">
              <option v-for="(op, op_index) in d_userShopBundleWdm['0']['parameters']['14'].options" :value="op.value">
                {{ op.translation[StoreLang] }}
              </option>
            </select>
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleWdm['0']['parameters']['15']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
          </b-col>
        </b-row>
        <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
          <b-col sm="12" lg="1">
            {{ lang.label }}
          </b-col>
          <b-col sm="12" lg="11">
            <b-form-input type="text" v-model="d_userShopBundleData.expiration_time_value[lang.label]"></b-form-input>
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleWdm['0']['parameters']['16']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
          </b-col>
        </b-row>
        <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
          <b-col sm="12" lg="1">
            {{ lang.label }}
          </b-col>
          <b-col sm="12" lg="11">
            <b-form-input type="text" v-model="d_userShopBundleData.expiration_time_value_by_text[lang.label]"></b-form-input>
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleWdm['0']['parameters']['17']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
          </b-col>
        </b-row>
        <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
          <b-col sm="12" lg="1">
            {{ lang.label }}
          </b-col>
          <b-col sm="12" lg="11">
            <b-form-input type="text" v-model="d_userShopBundleData.organization_token[lang.label]"></b-form-input>
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="4">
            <strong>{{ d_userShopBundleWdm['0']['parameters']['19']['name']['translation'][StoreLang] }}</strong>
          </b-col>
          <b-col sm="12" lg="8">
            <select class="form-control" v-model="d_userShopBundleData.bundle_type">
              <option v-for="(op, op_index) in d_userShopBundleWdm['0']['parameters']['19'].options" :value="op.value">
                {{ op.translation[StoreLang] }}
              </option>
            </select>
          </b-col>
        </b-row>
        <hr>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="6">
          </b-col>
          <b-col sm="12" lg="6" style="text-align: right;">
            <b-button variant="secondary" @click="f_addNewFeatureItem()">
              <i class="fa fa-plus-circle"></i> yeni özellik
            </b-button>
          </b-col>
        </b-row>
        <template v-if="d_userShopBundleData.feature.length > 0">
          <b-row v-for="(feature, feature_index) in d_userShopBundleData.feature" :style="f_calculateFeatureItemStyle(feature_index)">
            <b-col sm="12" lg="12">
              <b-row style="margin: 3px;">
                <b-col sm="12" lg="10">
                  <strong>{{ feature_index + 1 }} )</strong>
                </b-col>
                <b-col sm="12" lg="2">
                  <b-button variant="danger" @click="f_deleteFeatureItem(feature_index)">
                    {{ StoreLangTranslation.data['delete'][StoreLang] }}
                  </b-button>
                </b-col>
              </b-row>
              <b-row style="margin: 3px;">
                <b-col sm="12" lg="4">
                  <strong>{{ d_userShopBundleWdm['0']['parameters']['2']['parameters']['11']['name']['translation'][StoreLang] }}</strong>
                </b-col>
                <b-col sm="12" lg="8">
                  <select class="form-control" v-model="feature.feature_type">
                    <option v-for="(op, op_index) in d_userShopBundleWdm['0']['parameters']['2']['parameters']['11'].options" :value="op.value">
                      {{ op.translation[StoreLang] }}
                    </option>
                  </select>
                </b-col>
              </b-row>
              <hr>
              <b-row style="margin: 3px;">
                <b-col sm="12" lg="4">
                  <strong>{{ d_userShopBundleWdm['0']['parameters']['2']['parameters']['3']['name']['translation'][StoreLang] }}</strong>
                </b-col>
                <b-col sm="12" lg="8">
                </b-col>
              </b-row>
              <b-row style="margin: 3px;" v-for="(lang, lang_index) in StoreLangList.data">
                <b-col sm="12" lg="1">
                  {{ lang.label }}
                </b-col>
                <b-col sm="12" lg="11">
                  <b-form-textarea class="form-control" rows="5" v-model="feature.feature_caption[lang.label]"></b-form-textarea>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </template>
        <b-row style="margin: 3px;">
          <b-col sm="12" lg="6">
          </b-col>
          <b-col sm="12" lg="6">
            <b-button variant="secondary" @click="f_userShopBundleRecord()">
              {{ StoreLangTranslation.data['save'][StoreLang] }}
            </b-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col sm="12" lg="2"></b-col>
    </b-row>
  </div>
</template>

<script>
import { ClsWdm } from '@/wam/modules/wdm';
import { ClsRoute } from '@/wam/modules/route';
import store from '@/store';
import { ServiceFunctions } from '@/wam/modules/service';
import {
  default as PageInfo
} from '@/components/widgets/PageInfo';
import { mapGetters } from 'vuex';
export default {
  name: 'user_shop_bundle_record',
  computed: mapGetters({
    StoreDevice: 'StoreDevice',
    StoreLang: 'StoreLang',
    StoreLangList: 'StoreLangList',
    StoreLangTranslation: 'StoreLangTranslation',
    StoreFrontendSettings: 'StoreFrontendSettings',
  }),
  components: {
    PageInfo,
  },
  props: {},
  data () {
    return {
      d_user: '',
      d_frontendClientID: '',
      d_userShopClientID: '',
      d_userShopBundleClientID: '',
      d_frontendList: [],
      d_userShopBundleWdm: '',
      d_userShopBundleData: {
        'status': '0',
        'bundle_name': {},
        'bundle_group': {},
        'organization_id': {},
        'organization_name': {},
        'organization_user_id': {},
        'organization_username': {},
        'priority': '0',
        'task_type': '2',
        'feature': [],
        'available_soon': '1',
        'prices_on_the_screen': '0',
        'in_store_visibility': '1',
        'can_customer_add_to_shopcart': '1',
        'expiration_time_type': '2',
        'expiration_time_value': {},
        'expiration_time_value_by_text': {},
        'organization_token': {},
        'bundle_type': '0',
      }
    };
  },
  created: function () {
    ClsWdm.getLayerWdm('usr_shp_bund').then(resp_wdm_data => { this.d_userShopBundleWdm = resp_wdm_data; });
    this.d_user = JSON.parse(localStorage.getItem('user'));
  },
  beforeMount () {},
  mounted: function () {
    if (this.$route.query.user_shop_client_id) {
      this.d_userShopClientID = this.$route.query.user_shop_client_id;
    }
    if (this.d_userShopClientID) {
      if (this.$route.query.user_shop_bundle_client_id) {
        this.d_userShopBundleClientID = this.$route.query.user_shop_bundle_client_id;
        this.f_userShopBundleGet();
      }
    } else {
      alert(this.StoreLangTranslation.data['shop_is_not_selected'][this.StoreLang]);
      this.f_goToShopList();
    }
    if (!this.d_userShopBundleClientID) {
      this.f_langEligibleParameterProcess();
    }
    // console.log(this.StoreLangList);
    // console.log(this.StoreFrontendSettings);
    // console.log(this.d_userShopBundleWdm);
  },
  methods: {
    f_deleteFeatureItem: function (feature_index) {
      this.d_userShopBundleData.feature.splice(feature_index, 1);
    },
    f_addNewFeatureItem: function () {
      let lang_eligible_variables = ['feature_caption'];
      let new_feature_item = { 'feature_caption': {}, 'feature_type': '' };
      for (let k in lang_eligible_variables) {
        for (let lang_index in this.StoreLangList.data) {
          new_feature_item[lang_eligible_variables[k]][this.StoreLangList.data[lang_index].label] = '';
        }
      }
      this.d_userShopBundleData.feature.push(new_feature_item);
    },
    f_calculateFeatureItemStyle: function (feature_index) {
      let style = 'margin: 3px;';
      if (feature_index % 2 === 0) {
        style += 'background-color: #99e4e8';
      } else {
        style += 'background-color: #99e4e8';
        // style += 'background-color: white;';
      }
      return style;
    },
    f_goToShopList: function () {
      this.$router.push({ path: ClsRoute.f_getRoutePath('user_shop_list'), query: {} });
    },
    f_langEligibleParameterProcess: function () {
      let lang_eligible_variables = ['bundle_name', 'organization_id', 'organization_name', 'organization_user_id', 'organization_username', 'expiration_time_value', 'expiration_time_value_by_text', 'organization_token'];
      for (let k in lang_eligible_variables) {
        for (let lang_index in this.StoreLangList.data) {
          if (!this.d_userShopBundleData[lang_eligible_variables[k]][this.StoreLangList.data[lang_index].label]) {
            this.d_userShopBundleData[lang_eligible_variables[k]][this.StoreLangList.data[lang_index].label] = '';
          }
        }
      }
    },
    f_userShopBundleRecord: function () {
      let query = 'user_client_id=' + this.d_user.username;
      let data = {};
      if (this.d_userShopBundleClientID) {
        query += '&user_shop_bundle_client_id=' + this.d_userShopBundleClientID;
      }
      if (this.d_userShopClientID) {
        query += '&user_shop_client_id=' + this.d_userShopClientID;
      }
      for (let i in this.d_userShopBundleData) {
        data[i] = this.d_userShopBundleData[i];
      }
      if (this.d_userShopBundleClientID) {
        ServiceFunctions.user_shop_bundle_edit(query, data)
          .then(resp => {
            if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            } else {
              alert(resp.data.status_code + ' = ' + resp.data.status_message);
            }
          });
      } else {
        ServiceFunctions.user_shop_bundle_new(query, data)
          .then(resp => {
            if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            } else {
              alert(resp.data.status_code + ' = ' + resp.data.status_message);
            }
          });
      }
    },
    f_userShopBundleGet: function (user_shop_bundle_client_id) {
      let query = 'user_shop_bundle_client_id=' + this.d_userShopBundleClientID;
      query += '&data_type=value_mode';
      query += '&user_shop_client_id=' + this.d_userShopClientID;
      query += '&user_client_id=' + this.d_user.username;
      let data = {};
      ServiceFunctions.user_shop_bundle_get(query, data)
        .then(resp => {
          if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
            if (resp.data.data) {
              this.d_userShopBundleData = resp.data.data;
              this.f_langEligibleParameterProcess();
            } else {
              alert(this.StoreLangTranslation.data['process_completed'][this.StoreLang]);
            }
          } else {
            alert(resp.data.status_code + ' = ' + resp.data.status_message);
          }
        });
    },
  }
};

</script>

